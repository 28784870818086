import React, { useEffect, useState } from "react";
import "../css/work.css";
import Prismic from "@prismicio/client";
import { Link } from "react-router-dom";

export default function Work() {
  const apiEndpoint = "https://miniature.prismic.io/api/v2";
  const accessToken =
    "MC5ZZHh2dGhNQUFDWUFqbkU5.DAhf77-977-977-9KVY377-9cO-_ve-_ve-_ve-_vUoC77-9XBNDdgzvv71y77-977-9P3Pvv70u77-9";

  const client = Prismic.client(apiEndpoint, { accessToken });
  const [doc, setDoc] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await client.query(
      Prismic.Predicates.at("document.type", "work")
    );
    if (response) {
      setDoc(response.results);
      console.log("harshit", response.results);
    }
  };

  return (
    <div className="work-main">
      {/* <h1>selected work</h1> */}
      <div className="project-latest">
        <Link to={`/work-detail/${doc[0]?.data.content_relationship.uid}`}>
          <div className="project-latest-image">
            <video
              className="thumbnail"
              src={doc[0]?.data.thumbnail}
              muted
              playsInline
              loop="loop"
              autoPlay="autoplay"
            ></video>
          </div>
        </Link>

        <div className="project-description-div">
          <div className="project-desc">
            <p className="project-latest-title">{doc[0]?.data.title}</p>
            <p className="project-latest-description">
              {doc[0]?.data.description}
            </p>
          </div>

          <Link
            className="case-study-btn"
           to={`/work-detail/${doc[0]?.data.content_relationship.uid}`}
          >
            Read Case Study →
          </Link>
        </div>
      </div>
      <div className="project-latest">
        <Link to={`/work-detail/${doc[1]?.data.content_relationship.uid}`}>
          <div className="project-latest-image">
            <video
              className="thumbnail"
              src={doc[1]?.data.thumbnail}
              muted
              playsInline
              loop="loop"
              autoPlay="autoplay"
            ></video>
          </div>
        </Link>
        <div className="project-description-div">
          <div className="project-desc">
            <p className="project-latest-title">{doc[1]?.data.title}</p>
            <p className="project-latest-description">
              {doc[1]?.data.description}
            </p>
          </div>

          <Link
            className="case-study-btn"
           to={`/work-detail/${doc[1]?.data.content_relationship.uid}`}
          >
            Read Case Study →
          </Link>
        </div>
      </div>
      <div className="project-latest">
        <Link to={`/work-detail/${doc[2]?.data.content_relationship.uid}`}>
          <div className="project-latest-image">
            {doc[2]?.data.images?.map((e) => (
              <>
                {e.content_type == "Image" ? (
                  <img className="thumbnail" src={e.image_link} />
                ) : (
                  <video
                    className="thumbnail"
                    src={e.video_link}
                    muted
                    playsInline
                    loop="loop"
                    autoPlay="autoplay"
                  ></video>
                )}
              </>
            ))}
          </div>
        </Link>
        <div className="project-description-div">
          <div className="project-desc">
            <p className="project-latest-title">{doc[2]?.data.title}</p>
            <p className="project-latest-description">
              {doc[2]?.data.description}
            </p>
          </div>

          <Link
            className="case-study-btn"
           to={`/work-detail/${doc[2]?.data.content_relationship.uid}`}
          >
            Read Case Study →
          </Link>
        </div>
      </div>
      <div className="start-day">
        <h1>
          Start Within a Day! <br />
          How it Works?
        </h1>
        <div className="howitworks-main">
          <div className="howitworks-sub">
            <div className="howitworks1">
              <h2 className="book-min">
                <h3>01.</h3>Book Your Free 15-Min Design Audit Call
              </h2>
              <p>
                Nice to meet you. We'll discuss your design and business needs
                together. It's a chance for you to ask questions and ensure
                we're a good fit before moving forward.
              </p>
            </div>
            <Link
              className="project-btn2"
              style={{ textDecoration: "none", marginLeft: "50px" }}
              href="mailto:hello@theminiature.club"
              to="/work"
            >
              Book a Call
            </Link>
          </div>

          <div className="howitworks">
            <h2 className="book-min">
              <h3>02.</h3>Start Your Subscription
            </h2>
            <p>
              If our service is a good match, start your subscription for
              ongoing access to our design services. Choose update frequency
              based on your needs and budget.
            </p>
          </div>

          <div className="howitworks">
            <h2 className="book-min">
              <h3>03.</h3>Design Kick Off
            </h2>
            <p>
              Next we discuss your design requirements in depth. Branding,
              audience, and business goals to make sure the final product aligns
              with your vision and objectives.
            </p>
          </div>
          <div className="howitworks">
            <h2 className="book-min">
              <h3>04.</h3>To the Moon 🚀
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}
