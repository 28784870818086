import React from "react";
import "../css/home.css";
import { Link } from "react-router-dom";
import scroll from "../Assets/scroll.svg";
import LogoText from "../Assets/text_logo.svg";
import Pattern from "../Assets/pattern.svg";
export default function Home() {
  return (
    <div className="hero">
    
      <div className="head-text-div">
        <h1>
          <img
            className="logo-text"
            style={{ color: "white" }}
            src={LogoText}
          ></img>
        </h1>
        <div className="div-reach">
          <p className="text-a">
            We help products to figure out things that matter
          </p>
          <p className="text-p">
            {" "}
            <a>Check out our work →</a>
          </p>
        </div>
      </div>
      <div className="head-img-div">
        {/* <div className="scroll-more">
          <span>
            <img style={{ height: "40px" }} src={scroll} />
          </span>

          <p>Scroll</p>
        </div> */}
        {/* <img className="head-img" src={head} /> */}
      </div>
    </div>
  );
}
