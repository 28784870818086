import React from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";

export default function Testimonial() {
  return (
    <div>
      <h1 className="test-head">
        What our <span>clients</span> say <span>about us</span>
      </h1>
      <div className="testimonial-div">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
          slidesPerView={1}
          navigation
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          breakpoints={{
            // when window width is >= 640px
          
           
            1024: {
              slidesPerView: 2,
              spaceBetween: 50,
            },
          }}
        >
          <SwiperSlide>
            <div className="testimonial 1">
              <p className="test-body">
                Working with Miniature was instrumental in boosting our image.
                They captured the essence of 'Empowering Your Rapid Growth with
                Chat Commerce' perfectly, creating a website that's both
                informative and visually appealing. We've seen a significant
                increase in user engagement and a clearer understanding of our
                value proposition.
              </p>
              <div style={{ paddingBottom: "30px" }}>
                <p className="author-name">Wing Yuen</p>
                <p className="company">Rum Work, Co-founder</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonial 2">
              <p className="test-body">
                Miniature completely grasped our vision for WOO, the fintech app
                we envisioned. Their design is not only stunning but also
                functional, making it a breeze to manage finances. The Miniature
                team was responsive, collaborative, and a pleasure to work with.
                I highly recommend them for any fintech design project.
              </p>
              <div style={{ paddingBottom: "30px" }}>
                <p className="author-name">Rory MacQueen</p>
                <p className="company">Woo, Co-founder</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonial 3">
              <p className="test-body">
                We've gone from financial chaos to clarity, with real-time
                visibility, effortless automation, and data-driven decision
                making that fuels our growth. I highly recommend Miniature to
                any company seeking a powerful and user-friendly financial
                solution.
              </p>
              <div style={{ paddingBottom: "30px" }}>
                <p className="author-name">Rohit Agarwal</p>
                <p className="company">Product lead</p>
              </div>
            </div>
          </SwiperSlide>
          {/* <SwiperSlide>
            <div className="testimonial 4">
              <p className="test-body">
                Lorem oLorem ipsum dolor sit amet consectetur. Egestas turpis ut
                porttitor amet sodales augue nibh. Posuere turpis vitae iaculis
                semper lacus at ut. Sit condimentum ipsum amet bibendum aliquam
                lacus.
              </p>
              <div style={{ paddingBottom: "30px" }}>
                <p className="author-name">Rory Gonzalez</p>
                <p className="company">Woo, Co-founder</p>
              </div>
            </div>
          </SwiperSlide> */}
          ...
        </Swiper>
      </div>
    </div>
  );
}
