import Home from "./home";
import Nav from "./nav";
import Work from "./work";
import Footer from "./footer";
import About from "./about";
import Testimonial from "./testimonials";
import GetInTouch from "./getintouch";
import Approach from "./approach";
import ScrollamaDemo from "./scrollytelling";
import Marquee from "react-fast-marquee";
import Pattern from "../Assets/pattern.svg";

function Miniature() {
  return (
    <div className="App">
      <Nav />
      <img
        className="pattern"
        style={{ color: "white" }}
        src={Pattern}
      ></img>
      <Home />
      <Work />
      <Approach />
     

      {/* <Approach /> */}
      {/* <About/> */}
      <Marquee autoFill="true">
        <p className="p1">Strategy</p>
        <p className="p2">Design</p>
        <p className="p1">Integration</p>
        <p className="p2">Collaboration</p>
        <p className="p1">Quality</p>
        <p className="p2">Innovation</p>
      </Marquee>
      <Testimonial />
      {/* <ScrollamaDemo /> */}
      <GetInTouch />
      <Footer />
    </div>
  );
}

export default Miniature;
